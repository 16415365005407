// __webpack_public_path__ is a webpack global that lets you inject a runtime
// value for publicPath instead of having to set it at build time
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let __webpack_public_path__: string | undefined;

// Grab the configured CDN_URL *or* make a guess based on the URL of the asset bundle this
// is running in. This will only run on devbox and we can kill it after everyone has the updated
// docker-compose.yml that provides the RUNTIME_CONFIG option
// eslint-disable-next-line prefer-const
__webpack_public_path__ =
  window.__orinoco_config.CDN_URL ??
  // eslint-disable-next-line compat/compat
  (document.currentScript as HTMLScriptElement | null)?.src
    ?.split('/')
    ?.slice(0, -3)
    ?.join('/')
    ?.concat('/');

import {init as FSinit} from '@fullstory/browser';
import {App, ConfigProvider} from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {Loading} from 'Loading';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(localeData);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const Root = React.lazy(() => import('./Root').then(m => ({default: m.Root})));

FSinit({orgId: 'Z17Y9', devMode: window.__orinoco_config.ENV !== 'prod'});

// If a user direct links to an old /#/hash-style URL, we replace it with a direct
// admin/push-style URL before the React app actually loads. This only happens once,
// on page load. If a <Link> in the app has the #/ hardcoded, we won't catch that.
// Because we rely on removing the preceding / in the hash URL, we trust that the
// browser will append what was behind the #/ to the existing pathname in the URL,
// not create a new path from the root /. This may be fragile. Author: nickjs
if (location.hash.startsWith('#/')) {
  const rewrite = location.hash.replace('#/', '');

  const realSearch = location.search.replace(/\/$/, '');
  const hashSearch = rewrite.indexOf('?') !== -1; // if hash contains a search ?, combine it with the real one preceding the hash
  const search = hashSearch ? realSearch.replace('?', '&') : realSearch;

  history.replaceState(null, document.title, rewrite + search);
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <ConfigProvider
    theme={{
      hashed: false,
      token: {
        // SeedTokens
        borderRadius: 2,
        colorError: '#ff4d4f',
        colorInfo: '#1890ff',
        colorLink: '#592c82',
        colorPrimary: '#592c82',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontSize: 14,

        // MapTokens
        colorPrimaryBg: '#fafafa',
        colorText: '#000000d9',
        colorTextSecondary: '#000000a6',
        fontSizeHeading2: 18,
        fontSizeHeading3: 14,
        fontSizeHeading4: 12,

        // AliasTokens
        boxShadowSecondary: '10px 10px 19px -7px rgba(0, 0, 0, 0.6)',
        colorHighlight: '#fffb8f',
        colorTextDisabled: '#00000073',
        colorTextHeading: '#000000d9',
        linkDecoration: 'none',
      },
      components: {
        Button: {
          lineWidthFocus: 0,
        },
        Modal: {
          contentBg: '#ffffff',
          headerBg: '#ffffff',
        },
      },
    }}>
    <App>
      <React.Suspense fallback={<Loading fullscreen={true} />}>
        <Root />
      </React.Suspense>
    </App>
  </ConfigProvider>,
);
